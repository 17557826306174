import React from "react";
import BackgroundHeaderGradient from "../../assets/svg/background-header-gradient";
import useGradientScrollOpacity from "../../hooks/useGradientScrollOpacity";
import NavigationLegal from "../../components/navigation/NavigationLegal";
import Header from "../../atoms/blog/Header";
import "./PrivacyPolicy.scss";
import Paragraph from "../../atoms/blog/Paragraph";

const PrivacyPolicy = () => {
  const { headerStyle: landingPageHeaderStyle } = useGradientScrollOpacity();

  return (
    <div className="app-wrapper">
      <div
        style={landingPageHeaderStyle}
        className="app-background-header-gradient"
      >
        <BackgroundHeaderGradient
          height={1900}
          width={1900}
          fillOpacity={"0.6"}
          fillOpacityWhite={"0.4"}
          fill="var(--color-accent-one)"
        />
      </div>
      <NavigationLegal />
      <div className="legal-wrapper">
        <Header
          text="Waivlength Website - Privacy Policy"
          tagText="PRIVACY POLICY"
          secondaryText="Last update: 9 May 2024"
        />
        <Paragraph secondaryText='This Waivlength Website - Privacy Policy is intended to inform users of Waivlengths approach to privacy in respect of its website ("Website"). This Privacy Policy applies solely to information collected through the Waivlength website. For privacy practices related to Waivlength Software available through app stores, please refer to the privacy policy provided at the point of download or within the software itself.' />
        <Paragraph
          text="1. Who We Are"
          secondaryText="Whenever 'Waivlength' or 'we' is mentioned in this Privacy Policy, we are referring to Max Lambda Limited, trading as Waivlength, incorporated in the Republic of Ireland. Our contact information is available on our website and at the end of this Privacy Policy."
        />
        <Paragraph
          text="2. Personal Data Collection and Use"
          secondaryText="Waivlength limits the collection and processing of personal data from users of the Website. We collect the following personal data: Registration Information: Information you provide when registering on our Website for services such as early access to features or email communications. This may include your name, email address, or Ethereum address. Registration requires email verification through a unique link to confirm control of the email address. IP Addresses: We do not directly collect IP addresses; however, they may be collected by our hosting provider. See below for details on third-party data collection."
        />
        <Paragraph
          text="3. Third-Party Collection of Personal Data"
          secondaryText="Hosting Provider: Our hosting provider may collect personal data such as IP addresses and system configuration information as part of their hosting services. This data helps in managing and securing the Website and includes general location data derived from IP addresses. Cookies: We use essential cookies necessary for the Website’s functionality. Our hosting provider may set additional cookies necessary for the Website's operation."
        />
        <Paragraph
          text="4. Security Measures"
          secondaryText="We take data security seriously and implement a variety of security measures to maintain the safety of your personal data submitted to the Website. These measures protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our site."
        />
        <Paragraph
          text="5. Data Export Outside the European Union"
          secondaryText="Waivlength, along with our hosting provider, ensures that any personal data exported outside the EU or Ireland complies with adequate protection standards as required by EU and Irish laws. Data transfers occur only under conditions that meet these standards, including contractual clauses that guarantee the same level of protection."
        />
        <Paragraph
          text="6. Your Rights"
          secondaryText="You have specific rights regarding the personal data we hold about you. These include the rights to access, correct, delete, or transfer your data, withdraw consent for data processing (where consent is the basis of processing), and object to our processing of your personal data. Requests for exercising these rights can be directed to our contact details provided below."
        />
        <Paragraph
          text="7. Third-Party Links"
          secondaryText="Our Website may include links to third-party websites, over which we have no control. These sites have their own privacy policies, and we do not accept any responsibility or liability for these policies or sites."
        />
        <Paragraph
          text="8. Changes to This Privacy Policy"
          secondaryText="We reserve the right to modify this Privacy Policy at any time. Changes will be posted on the Website and effective immediately upon posting. We encourage users to frequently check this page for any changes."
        />
        <Paragraph
          text="9. Contact Information"
          secondaryText="If you have any questions about this Privacy Policy, please contact us at contact@waivlength.io."
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
