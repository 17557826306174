import React from "react";

const ContactUsIcon = (props) => {
  const { width, height, stroke, strokeWidth } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7"
        stroke={stroke || "#292D32"}
        strokeWidth={strokeWidth || "1.5"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
        stroke={stroke || "#292D32"}
        strokeWidth={strokeWidth || "1.5"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16.5H8"
        stroke={stroke || "#292D32"}
        strokeWidth={strokeWidth || "1.5"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.5H5"
        stroke={stroke || "#292D32"}
        strokeWidth={strokeWidth || "1.5"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContactUsIcon;
