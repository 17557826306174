import React, { useState, useEffect } from "react";
import "./FutureFeaturesCarousel.scss";
import CarouselItem from "./CarouselItem";
import ArrowIcon from "../../assets/svg/arrow-icon";
import { useSwipeable } from "react-swipeable";

const FutureFeaturesCarousel = () => {
  const items = [1, 2, 3, 4, 5];
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleItems =
    window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 2 : 1;
  const [totalItemWidth, setTotalItemWidth] = useState(0);

  useEffect(() => {
    const carouselContainer = document.querySelector(
      ".features-carousel-container"
    );
    if (carouselContainer) {
      const carouselWidth = carouselContainer.offsetWidth;
      const gapWidth =
        window.innerWidth >= 1024 ? 12 : window.innerWidth >= 768 ? 12 : 12;
      const itemWidth = carouselWidth / visibleItems - gapWidth;
      const itemHeight = itemWidth / 2; // Maintaining the 2:1 aspect ratio
      carouselContainer.style.setProperty("--item-height", `${itemHeight}px`); // Setting the height dynamically
      setTotalItemWidth(100 / visibleItems + (gapWidth / carouselWidth) * 100);
    }
  }, [visibleItems]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, items.length - visibleItems)
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true, // Prevent default scrolling
    trackMouse: true, // Allows testing on desktop
  });

  return (
    <div className="features-container">
      <div className="features-buttons-container">
        <button
          className="features-button"
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          <ArrowIcon
            stroke="var(--color-bg-secondary)"
            strokeWidth={2}
            height={16}
            width={16}
            rotate={180}
            opacity={currentIndex === 0 ? 0.5 : 1}
          />
        </button>
        <button
          className="features-button"
          onClick={handleNext}
          disabled={currentIndex >= items.length - visibleItems}
        >
          <ArrowIcon
            stroke="var(--color-bg-secondary)"
            strokeWidth={2}
            height={16}
            width={16}
            rotate={0}
            opacity={currentIndex >= items.length - visibleItems ? 0.5 : 1}
          />
        </button>
      </div>
      <div className="features-wrapper">
        <div
          className="features-carousel-container"
          style={{
            transform: `translateX(-${currentIndex * totalItemWidth}%)`,
          }}
          {...handlers}
        >
          {items.map((num) => (
            <CarouselItem key={num} index={num}>
              Item {num}
            </CarouselItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FutureFeaturesCarousel;
