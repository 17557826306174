import React from "react";
import "./SectionLabel.scss";

const SectionLabel = ({ buttonText, IconComponent }) => {
  return (
    <div className="section-label">
      <div className="section-icon-component">
        <IconComponent
          stroke="var(--color-bg-secondary)"
          strokeWidth={2}
          height={16}
          width={16}
        />
      </div>
      <span className="section-label-text">{buttonText}</span>
    </div>
  );
};

export default SectionLabel;
