import React, { useState, useEffect } from "react";
import "./Footer.scss";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import LightningIcon from "../../assets/svg/lightning-icon";
import useScrollTo from "../../hooks/useScrollTo";
import { addEmailAndAddress } from "../../elements/sign-up-dialog/Firebase";
import { validateAndExecute } from "../../utils/validateAndExecute";
import { Link } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [dimensions, setDimensions] = useState({ width: 140, height: 80 });

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setDimensions({ width: 60, height: 40 });
      } else {
        setDimensions({ width: 80, height: 50 });
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToFeatures = useScrollTo(2648);
  const scrollToCommunities = useScrollTo(4832);
  const scrollToComingSoon = useScrollTo(7835);
  const handleAddEmail = (acceptTermsAndPrivacy) => {
    validateAndExecute({
      input: email,
      inputType: "email",
      acceptTermsAndPrivacy: acceptTermsAndPrivacy,
      validateInput: {
        isValid: (email) => email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
        failureMessage: "Email is invalid",
        logMessage: "Email check failed: Email is invalid",
      },
      action: addEmailAndAddress,
      onSuccess: (msg) => {
        setMessage(msg);
      },
      onError: (msg) => {
        setMessage(msg);
      },
      onValidationFailure: setValidationMessage,
      resetInput: () => setEmail(""),
    });
  };

  const openMedium = () =>
    window.open("https://medium.com/@waivlengthdapp", "_blank");
  const openLinkedIn = () =>
    window.open("https://www.linkedin.com/company/waivlengthapp", "_blank");
  const openTwitter = () =>
    window.open("https://twitter.com/waivlength_io", "_blank");

  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-header-wrapper">
          <div className="footer-header-logo-wrapper">
            <WaivlengthLogo fill="var(--color-bg-primary)" {...dimensions} />
          </div>
          <div className="input-container">
            <input
              className="sign-up-input"
              placeholder={message || "Enter your email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="footer-container-text-button">
              <span className="footer-primary-text">
                {validationMessage ||
                  "Sign up for our newsletter and join the growing Waivlength community"}
              </span>
              <button
                className="footer-button-container"
                onClick={() => handleAddEmail(false)}
              >
                <LightningIcon
                  stroke="var(--color-text-primary)"
                  strokeWidth={3}
                  height={16}
                  width={16}
                />
                <span className="footer-button-signup-text">Sign up</span>
              </button>
            </div>
          </div>
        </div>
        <div className="footer-body-wrapper">
          <div className="footer-body-waivlength-wrapper">
            <span className="footer-secondary-text">© Waivlength 2024</span>
          </div>
          <div className="footer-body-grid-container">
            <div className="grid-container">
              <div className="grid-container-column">
                <div className="column-header">
                  <span className="footer-body-header-text">Highlights</span>
                </div>
                <div className="grid-item">
                  <span
                    className="footer-body-body-text"
                    onClick={scrollToFeatures}
                  >
                    Features
                  </span>
                </div>
                <div className="grid-item">
                  <span
                    className="footer-body-body-text"
                    onClick={scrollToCommunities}
                  >
                    Communities
                  </span>
                </div>
                <div className="grid-item">
                  <span
                    className="footer-body-body-text"
                    onClick={scrollToComingSoon}
                  >
                    Coming Soon
                  </span>
                </div>
              </div>
              <div className="grid-container-column">
                <div className="column-header">
                  <span className="footer-body-header-text">Company</span>
                </div>
                <div className="grid-item">
                  <Link
                    to="/legal/terms-of-use"
                    className="footer-body-body-text"
                  >
                    Terms of Use
                  </Link>
                </div>
                <div className="grid-item">
                  <Link
                    to="/legal/privacy-policy"
                    className="footer-body-body-text"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
              <div className="grid-container-column">
                <div className="column-header">
                  <span className="footer-body-header-text">Socials</span>
                </div>
                <div className="grid-item" onClick={openTwitter}>
                  <span className="footer-body-body-text">Twitter</span>
                </div>
                <div className="grid-item" onClick={openMedium}>
                  <span className="footer-body-body-text">Medium</span>
                </div>
                <div className="grid-item" onClick={openLinkedIn}>
                  <span className="footer-body-body-text">LinkedIn</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
