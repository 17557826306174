import React, { useRef, useEffect } from "react";
import "./AppDisplay.scss";
import video1 from "../../assets/video/landing.mp4";

const AppDisplay = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      console.error("Video element not found!");
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoElement.play().catch((error) => {
              console.error("Error attempting to play video:", error);
            });
          } else {
            videoElement.pause();
          }
        });
      },
      { root: null, threshold: 0.05, rootMargin: "0px" }
    );

    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
    };
  }, []);

  const videoSourceWithTime = `${video1}#t=0.01`;

  return (
    <div className="app-display-container">
      <div className="app-display-screen">
        <video ref={videoRef} loop muted playsInline preload="auto">
          <source src={videoSourceWithTime} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default AppDisplay;
