import React from "react";
import "./FeaturedSpaceItem.scss";

const FeaturedSpaceItem = ({
  imageSrc,
  transformX,
  transformY,
  width,
  height,
  zIndex,
}) => {
  return (
    <div
      className="featured-space-item"
      style={{
        backgroundImage: `url(${imageSrc})`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "absolute",
        borderRadius: "12px",
        left: "50%",
        top: "50%",
        transform: `translate(-50%, -50%) translate(${transformX}px, ${transformY}px)`,
        zIndex: zIndex,
      }}
    ></div>
  );
};

export default FeaturedSpaceItem;
