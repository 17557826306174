import { useEffect, useState } from "react";

const useTransformControlBanner = (startPosition, range, stay) => {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: "scale(0.8) translateY(0px)",
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.pageYOffset;
      const endPosition = startPosition + range + stay;
      const offset = Math.max(0, scrolled - (startPosition + range + stay));

      if (scrolled < startPosition) {
        setStyle({
          opacity: 0,
          transform: "scale(0.8) translateY(0px)",
        });
      } else if (
        scrolled >= startPosition &&
        scrolled <= startPosition + range
      ) {
        const progress = (scrolled - startPosition) / range;
        setStyle({
          opacity: progress,
          transform: `scale(${0.8 + 0.2 * progress}) translateY(0px)`,
        });
      } else if (scrolled > startPosition + range && scrolled <= endPosition) {
        setStyle({
          opacity: 1,
          transform: "scale(1) translateY(0px)",
        });
      } else {
        setStyle({
          opacity: 1,
          transform: `scale(1) translateY(-${offset}px)`,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [startPosition, range, stay]);

  return style;
};

export default useTransformControlBanner;
