import React from "react";
import Banner from "../../elements/banner/Banner";
import useTransformControlBanner from "../../hooks/useTransformControlBanner";
import "./BannerSection.scss";

export const BannerSection = ({
  height,
  startPosition,
  range,
  stay,
  transitionTime,
  text,
  text1,
  text2,
  text3,
  text4,
  logoPosition,
}) => {
  const style = useTransformControlBanner(
    startPosition,
    range,
    stay,
    transitionTime
  );

  return (
    <div
      className="banner-section"
      style={{
        height: `${height}px`,
      }}
    >
      <div className="banner-section-content" style={style}>
        <Banner
          text={text}
          text1={text1}
          text2={text2}
          text3={text3}
          text4={text4}
          logoPosition={logoPosition}
        />
      </div>
    </div>
  );
};
