import React from "react";
import FeaturesIcon from "../../assets/svg/features-icon";
import "./FeaturesSection.scss";
import SectionInfoContainer from "../../elements/section-info-container/SectionInfoContainer";
import FeaturesCarousel from "../../elements/features-carousel/FeaturesCarousel";

const FeaturesSection = () => {
  return (
    <div className="features-section-container">
      <SectionInfoContainer
        buttonText="Features"
        IconComponent={FeaturesIcon}
        headerText="Enhance Your Community Dynamics"
        bodyText="Leverage Waivlength to effortlessly utilize decentralized messaging, tokenization, fundraising, and DAO governance, all from a single platform for enhanced Web3 collaboration."
      />
      <FeaturesCarousel />
    </div>
  );
};

export default FeaturesSection;
