import React from "react";
import BackgroundHeaderGradient from "../../assets/svg/background-header-gradient";
import useGradientScrollOpacity from "../../hooks/useGradientScrollOpacity";
import NavigationLegal from "../../components/navigation/NavigationLegal";
import Header from "../../atoms/blog/Header";
import "./TermsOfUse.scss";
import Paragraph from "../../atoms/blog/Paragraph";

const TermsOfUse = () => {
  const { headerStyle: landingPageHeaderStyle } = useGradientScrollOpacity();

  return (
    <div className="app-wrapper">
      <div
        style={landingPageHeaderStyle}
        className="app-background-header-gradient"
      >
        <BackgroundHeaderGradient
          height={1900}
          width={1900}
          fillOpacity={"0.6"}
          fillOpacityWhite={"0.4"}
          fill="var(--color-accent-one)"
        />
      </div>
      <NavigationLegal />
      <div className="legal-wrapper">
        <Header
          text="Waivlength Website - Terms of Use"
          tagText="TERMS OF USE"
          secondaryText="Last update: 9 May 2024"
        />
        <Paragraph secondaryText='This page is intended to inform users of the terms and conditions ("Website Terms of Use") applicable to your access and use of the Waivlength website ("Website"). For the terms of use regarding Waivlengths software, which facilitates enhanced project management and community interaction within the Web3 realm through tools for communication, tokenized environments, and decentralized governance ("Waivlength Software"), please refer to the Waivlength Terms of Use available in the app stores where the Waivlength Software is distributed.' />
        <Paragraph
          text="1. Introduction"
          secondaryText="By accessing or using the Website, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of the terms, you must not use the Website."
        />
        <Paragraph
          text="2. Acceptance of Terms"
          secondaryText="By accessing or using the Platform, you agree to be bound by these Terms and our Privacy Policy. If you disagree with any part of the terms, you must not use our Platform."
        />
        <Paragraph
          text="3. Changes to Terms"
          secondaryText="Waivlength offers a cutting-edge solution for managing decentralized communities and projects through features like token creation, fundraising, liquidity management, and governance, integrated with the Ethereum blockchain."
        />
        <Paragraph
          text="4. User Responsibilities"
          secondaryText="You are responsible for all your activities in connection with the use of the Waivlength Website. You must ensure that any information you provide on the Website is accurate and current."
        />
        <Paragraph
          text="5. Intellectual Property"
          secondaryText="
You agree not to use the Website for any unlawful purposes or in ways that could harm, damage, or disparage any party. Specifically, you are prohibited from impersonating any person or entity, misrepresenting your affiliation, or engaging in spamming, flooding, harvesting of personal information, screen scraping, database scraping, or any activities aimed at obtaining user lists or other data."
        />
        <Paragraph
          text="6. User Content"
          secondaryText="Users may have the opportunity to publish or share content through the Website. You retain any intellectual property rights in your User Content but grant Waivlength a worldwide, perpetual license to use, store, and distribute such content."
        />
        <Paragraph
          text="7. Prohibited Conduct"
          secondaryText="Waivlength retains all intellectual property rights in the Website and its content, including texts, graphics, logos, and software. You are granted a limited, non-exclusive license to access and use the Website for your informational and personal use in line with these Terms. However, you are prohibited from using any content from the Website for commercial purposes without explicit permission from Waivlength."
        />
        <Paragraph
          text="8. Disclaimers"
          secondaryText="The Website is provided on an 'as is' and 'as available' basis. Waivlength expressly disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. Waivlength does not warrant that the Website will be uninterrupted or error-free."
        />
        <Paragraph
          text="9. Limitation of Liabilityn"
          secondaryText="In no event will Waivlength be liable for any indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of the Website, whether or not Waivlength has been advised of the possibility of such damages."
        />
        <Paragraph
          text="10. Indemnification"
          secondaryText="You agree to indemnify and hold harmless Waivlength, its affiliates, officers, agents, employees, and partners from any claim or demand made by any third party due to or arising out of your access to the Website, use of the Website, violation of these Terms, or infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity."
        />
        <Paragraph
          text="11. Links to Other Sites"
          secondaryText="The Website may contain links to third-party websites that are not owned or controlled by Waivlength. Waivlength assumes no responsibility for the content, terms and conditions, privacy policies, or practices of any third-party websites."
        />
        <Paragraph
          text="12. Governing Law"
          secondaryText="These Terms shall be governed and construed in accordance with the laws of the Republic of Ireland, without regard to its conflict of law provisions."
        />
        <Paragraph
          text="13. Dispute Resolution"
          secondaryText="Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Dublin, Ireland."
        />
        <Paragraph
          text="14. Contact Information"
          secondaryText="If you have any questions about these Terms, please contact us at contact@waivlength.io"
        />
      </div>
    </div>
  );
};

export default TermsOfUse;
