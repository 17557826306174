import React from "react";
import "./FeaturedSalesSection.scss";
import FeaturedSalesCarousel from "../../elements/featured-sales-carousel/FeaturedSalesCarousel";

const FeaturedSalesSection = () => {
  return (
    <div className="featured-sales-section-container">
      <span className="featured-sales-primary-text">Featured Sales</span>
      <FeaturedSalesCarousel />
    </div>
  );
};

export default FeaturedSalesSection;
