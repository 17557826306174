import React from "react";

const SunIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5398_183)">
        <path
          d="M12 1C12.5063 1 12.9167 1.41041 12.9167 1.91667V3.75C12.9167 4.25626 12.5063 4.66667 12 4.66667C11.4937 4.66667 11.0833 4.25626 11.0833 3.75V1.91667C11.0833 1.41041 11.4937 1 12 1Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 12C6.5 8.96243 8.96243 6.5 12 6.5C15.0376 6.5 17.5 8.96243 17.5 12C17.5 15.0376 15.0376 17.5 12 17.5C8.96243 17.5 6.5 15.0376 6.5 12ZM12 8.33333C9.97496 8.33333 8.33333 9.97496 8.33333 12C8.33333 14.025 9.97496 15.6667 12 15.6667C14.025 15.6667 15.6667 14.025 15.6667 12C15.6667 9.97496 14.025 8.33333 12 8.33333Z"
          fill={fill}
        />
        <path
          d="M12.9167 20.25C12.9167 19.7437 12.5063 19.3333 12 19.3333C11.4937 19.3333 11.0833 19.7437 11.0833 20.25V22.0833C11.0833 22.5896 11.4937 23 12 23C12.5063 23 12.9167 22.5896 12.9167 22.0833V20.25Z"
          fill={fill}
        />
        <path
          d="M4.22015 4.22015C4.57813 3.86217 5.15854 3.86217 5.51652 4.22015L6.81818 5.52182C7.17616 5.8798 7.17616 6.4602 6.81818 6.81818C6.4602 7.17616 5.8798 7.17616 5.52182 6.81818L4.22015 5.51652C3.86217 5.15854 3.86217 4.57813 4.22015 4.22015Z"
          fill={fill}
        />
        <path
          d="M18.4782 17.1818C18.1202 16.8238 17.5398 16.8238 17.1818 17.1818C16.8238 17.5398 16.8238 18.1202 17.1818 18.4782L18.4835 19.7798C18.8415 20.1378 19.4219 20.1378 19.7798 19.7798C20.1378 19.4219 20.1378 18.8415 19.7798 18.4835L18.4782 17.1818Z"
          fill={fill}
        />
        <path
          d="M1 12C1 11.4937 1.41041 11.0833 1.91667 11.0833H3.75C4.25626 11.0833 4.66667 11.4937 4.66667 12C4.66667 12.5063 4.25626 12.9167 3.75 12.9167H1.91667C1.41041 12.9167 1 12.5063 1 12Z"
          fill={fill}
        />
        <path
          d="M20.25 11.0833C19.7437 11.0833 19.3333 11.4937 19.3333 12C19.3333 12.5063 19.7437 12.9167 20.25 12.9167H22.0833C22.5896 12.9167 23 12.5063 23 12C23 11.4937 22.5896 11.0833 22.0833 11.0833H20.25Z"
          fill={fill}
        />
        <path
          d="M6.81818 17.1818C7.17616 17.5398 7.17616 18.1202 6.81818 18.4782L5.51652 19.7798C5.15854 20.1378 4.57813 20.1378 4.22015 19.7798C3.86217 19.4219 3.86217 18.8415 4.22015 18.4835L5.52182 17.1818C5.8798 16.8238 6.4602 16.8238 6.81818 17.1818Z"
          fill={fill}
        />
        <path
          d="M19.7798 5.51652C20.1378 5.15854 20.1378 4.57813 19.7798 4.22015C19.4219 3.86217 18.8415 3.86217 18.4835 4.22015L17.1818 5.52182C16.8238 5.8798 16.8238 6.4602 17.1818 6.81818C17.5398 7.17616 18.1202 7.17616 18.4782 6.81818L19.7798 5.51652Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SunIcon;
