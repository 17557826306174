import React from "react";
import "./CommunitiesSection.scss";
import SectionInfoContainer from "../../elements/section-info-container/SectionInfoContainer";
import CommunitiesIcon from "../../assets/svg/communities-icon";
import FeaturedSpaces from "../../elements/featured-spaces/FeaturedSpaces";

const CommunitiesSection = () => {
  return (
    <div className="communities-section-container">
      <SectionInfoContainer
        buttonText="Communities"
        IconComponent={CommunitiesIcon}
        headerText="Discover Your Community"
        bodyText="Explore vibrant Web3 communities within Waivlength using the Discover Spaces feature, and connect and collaborate with others."
      />
      <div className="communities-table">
        <FeaturedSpaces />
      </div>
    </div>
  );
};

export default CommunitiesSection;
