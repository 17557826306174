import React from "react";
import "./Paragraph.scss";

function Paragraph({ text, secondaryText }) {
  return (
    <div className="blog-para-component">
      <span className="blog-para-primary-text">{text}</span>
      <span className="blog-para-secondary-text">{secondaryText}</span>
    </div>
  );
}

export default Paragraph;
