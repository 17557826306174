import React from "react";
import "./FutureFeaturesSection.scss";
import SectionInfoContainer from "../../elements/section-info-container/SectionInfoContainer";
import FutureFeaturesCarousel from "../../elements/future-features-carousel/FutureFeaturesCarousel";
import FutureFeaturesIcon from "../../assets/svg/future-features-icon";

const FutureFeaturesSection = () => {
  return (
    <div className="features-section-container">
      <SectionInfoContainer
        buttonText="Coming Soon"
        IconComponent={FutureFeaturesIcon}
        headerText="Future Features on the Horizon"
        bodyText="Discover upcoming Waivlength features: Smart Messaging, Token/NFT Gated Communities, DAO Governance, Liquidity Pools and our Point System. Enhance your Web3 experience."
      />

      <FutureFeaturesCarousel />
    </div>
  );
};

export default FutureFeaturesSection;
