import React, { useRef, useEffect } from "react";
import "./CarouselItem.scss";
import video1 from "../../assets/video/createSpace.mp4";
import video2 from "../../assets/video/communities.mp4";
import video3 from "../../assets/video/discover.mp4";

const videoSources = [video1, video2, video3];

const CarouselItem = ({ index, currentIndex }) => {
  const videoRef = useRef(null);
  const itemRef = useRef(null);
  const mobileBreakpoint = 430;

  useEffect(() => {
    const videoElement = videoRef.current;
    const itemElement = itemRef.current;

    if (!itemElement || !videoElement) {
      return;
    }

    const handleMouseEnter = () => {
      if (window.innerWidth > mobileBreakpoint) {
        videoElement.play();
      }
    };

    const handleMouseLeave = () => {
      if (window.innerWidth > mobileBreakpoint) {
        videoElement.pause();
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && window.innerWidth <= mobileBreakpoint) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    observer.observe(itemElement);

    itemElement.addEventListener("mouseenter", handleMouseEnter);
    itemElement.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      observer.disconnect();
      itemElement.removeEventListener("mouseenter", handleMouseEnter);
      itemElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [currentIndex]);

  const videoSourceWithTime = `${videoSources[index - 1]}#t=0.01`;

  return (
    <div className="features-carousel-item-wrapper" ref={itemRef}>
      <div className="features-carousel-item">
        <video ref={videoRef} loop muted playsInline preload="auto">
          <source src={videoSourceWithTime} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default CarouselItem;
