import React from "react";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import "./Navigation.scss";
import MoonIcon from "../../assets/svg/moon-icon";
import SunIcon from "../../assets/svg/sun-icon";
import { useTheme } from "../../contexts/ThemeContext";
import { Link } from "react-router-dom";
import ArrowIcon from "../../assets/svg/arrow-icon";

const NavigationLegal = () => {
  const { theme, toggleTheme } = useTheme();
  const isDarkTheme = theme === "dark-theme";

  return (
    <div className="landing-page-navigation">
      <div className="landing-page-navigation-logo-container">
        <WaivlengthLogo
          fill="var(--color-bg-secondary)"
          height={26}
          width={36}
        />
        <span className="landing-page-nav-logo-text">waivlength</span>
      </div>
      <div className="landing-page-navigation-sections"></div>
      <div className="landing-page-theme-button" onClick={toggleTheme}>
        {isDarkTheme ? (
          <SunIcon height={20} width={20} fill="var(--color-bg-secondary)" />
        ) : (
          <MoonIcon
            stroke="var(--color-bg-secondary)"
            strokeWidth={3}
            height={16}
            width={16}
          />
        )}
      </div>
      <Link
        to="/"
        className="landing-page-navigation-signup-button-container-link"
      >
        <button className="landing-page-navigation-signup-button-container">
          <ArrowIcon
            stroke="var(--color-text-secondary)"
            strokeWidth={3}
            height={16}
            width={16}
            rotate={180}
          />
          <span className="landing-page-nav-signup-text">Return to home</span>
        </button>
      </Link>
    </div>
  );
};

export default NavigationLegal;
