import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUpDialog from "./elements/sign-up-dialog/SignUpDialog";
import LandingPage from "./pages/landing/Landing";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import ScrollToTop from "./hooks/useScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <SignUpDialog />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
