import React from "react";
import useScrollTo from "../../hooks/useScrollTo";
import "./NavigationButtonDropDown.scss";

const NavigationButtonDropDown = ({
  buttonText,
  IconComponent,
  scrollPosition,
  onClick,
}) => {
  const scrollTo = useScrollTo(scrollPosition);

  const handleClick = () => {
    scrollTo();
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="navigation-button-wrapper-drop-down" onClick={handleClick}>
      <div className="icon-component-drop-down">
        <IconComponent
          stroke="var(--color-bg-secondary)"
          strokeWidth={2.4}
          height={16}
          width={16}
        />
      </div>
      <span className="navigation-button-text-drop-down">{buttonText}</span>
    </div>
  );
};

export default NavigationButtonDropDown;
