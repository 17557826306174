import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import "./FeaturedSalesCarousel.scss";
import CarouselItem from "./CarouselItem";
import image from "../../assets/image/Image.jpeg";
import image1 from "../../assets/image/Image1.png";
import image2 from "../../assets/image/Image2.png";
import banner from "../../assets/image/Banner.png";
import banner1 from "../../assets/image/Banner1.png";
import banner2 from "../../assets/image/Banner2.png";
import ArrowIcon from "../../assets/svg/arrow-icon";

const FeaturedSalesCarousel = () => {
  const featuredItems = [
    {
      id: 1,
      imageSrc: image1,
      bannerSrc: banner1,
      primaryText: "Void",
      secondaryText:
        "Void is a digital currency exchange emphasizing security and efficiency. Our token, $VEX, offers exclusive trading benefits.",
      tertiaryText: "VOID",
      minContribution: 1,
      maxContribution: 3,
      totalFundraiseGoal: 200,
      totalFundraised: 34,
    },
    {
      id: 2,
      imageSrc: image,
      bannerSrc: banner,
      primaryText: "ArborChain",
      secondaryText:
        "ArborChain offers blockchain solutions for sustainability, with EcoToken ($ARB) rewarding and tracking global environmental efforts.",
      tertiaryText: "ACB",
      minContribution: 0.05,
      maxContribution: 0.2,
      totalFundraiseGoal: 4,
      totalFundraised: 2.4,
    },
    {
      id: 3,
      imageSrc: image2,
      bannerSrc: banner2,
      primaryText: "LuxePixels",
      secondaryText:
        "The premier marketplace for exclusive digital masterpieces. Perfect for discerning collectors seeking to invest in the future of art.",
      tertiaryText: "LPX",
      minContribution: 0.1,
      maxContribution: 0.1,
      totalFundraiseGoal: 5,
      totalFundraised: 4.6,
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleItems =
    window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 2 : 1;
  const [totalItemWidth, setTotalItemWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const carouselWidth =
      document.querySelector(".featured-carousel-container")?.offsetWidth || 0;
    const itemWidth = 100 / visibleItems;
    const gapWidth = 12;
    const gapWidthPercent = (gapWidth / carouselWidth) * 100;
    setTotalItemWidth(itemWidth + gapWidthPercent);
  }, [visibleItems]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, featuredItems.length - visibleItems)
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true, // Prevent default scrolling
    trackMouse: true, // Allows testing on desktop
  });

  return (
    <div className="featured-sales-container">
      {windowWidth < 1024 && (
        <div className="featured-sales-buttons-container">
          <button
            className="features-button"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <ArrowIcon
              stroke="var(--color-bg-secondary)"
              strokeWidth={2}
              height={16}
              width={16}
              rotate={180}
              opacity={currentIndex === 0 ? 0.5 : 1}
            />
          </button>
          <button
            className="features-button"
            onClick={handleNext}
            disabled={currentIndex >= featuredItems.length - visibleItems}
          >
            <ArrowIcon
              stroke="var(--color-bg-secondary)"
              strokeWidth={2}
              height={16}
              width={16}
              rotate={0}
              opacity={
                currentIndex >= featuredItems.length - visibleItems ? 0.5 : 1
              }
            />
          </button>
        </div>
      )}
      <div className="featured-sales-wrapper">
        <div
          {...handlers}
          className="featured-carousel-container"
          style={{
            transform: `translateX(-${currentIndex * totalItemWidth}%)`,
          }}
        >
          {featuredItems.map((item) => (
            <CarouselItem key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedSalesCarousel;
