import { useEffect } from "react";

export function useLockBodyScroll(noScroll) {
  useEffect(() => {
    if (noScroll && sessionStorage.getItem("noScrollApplied") !== "true") {
      document.body.style.overflowY = "hidden";

      const timer = setTimeout(() => {
        document.body.style.overflowY = "auto";
        sessionStorage.setItem("noScrollApplied", "true");
      }, 3000);

      return () => {
        clearTimeout(timer);
        document.body.style.overflowY = "auto";
      };
    }
  }, [noScroll]);
}
