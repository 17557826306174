import React from "react";
import SectionLabel from "../../atoms/section-label/SectionLabel";
import "./SectionInfoContainer.scss";

const SectionInfoContainer = ({
  buttonText,
  IconComponent,
  headerText,
  bodyText,
}) => {
  return (
    <div className="section-info-container">
      <SectionLabel buttonText={buttonText} IconComponent={IconComponent} />
      <div className="section-info-text-container">
        <span className="header-primary-text">{headerText}</span>
        <span className="body-primary-text">{bodyText}</span>
      </div>
    </div>
  );
};

export default SectionInfoContainer;
