import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import AndroidAppStoreIcon from "../../assets/svg/android-app-store-icon";
import AppleAppStoreIcon from "../../assets/svg/apple-app-store-icon";
import useBounceInUpAnimation from "../../hooks/useBounceInUpAnimation";
import QRCode from "react-qr-code";
import "./AppStore.scss";

const AppStore = () => {
  const { style: landingPageStyle } = useBounceInUpAnimation(
    0,
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    2000,
    800
  );

  // App Store URLs
  const appStoreUrl =
    "https://apps.apple.com/ca/app/waivlength-mobile/id6503270846";
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=app.waivlength.mobile&pcampaignid=web_share";

  const [isMobile, setIsMobile] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(/android|iphone|ipad|iPod/i.test(userAgent));
    };

    checkMobile();
  }, []);

  const toggleDialog = (url) => {
    if (isMobile) {
      window.location.href = url;
    } else {
      setQrCodeUrl(url);
      setIsDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const Dialog = ({ qrCodeUrl }) => {
    return ReactDOM.createPortal(
      <div className="dialog-overlay" onClick={closeDialog}>
        <div className="dialog" onClick={(e) => e.stopPropagation()}>
          <div className="qr-code-container">
            <QRCode value={qrCodeUrl} size={160} />
          </div>
        </div>
      </div>,
      document.getElementById("dialog-root")
    );
  };

  return (
    <div className="app-landing-page-app-stores-wrapper">
      {isDialogOpen && <Dialog qrCodeUrl={qrCodeUrl} />}

      <div
        style={landingPageStyle}
        className="app-landing-page-app-stores-container"
      >
        <div
          onClick={() => toggleDialog(appStoreUrl)}
          style={{ cursor: "pointer" }}
        >
          <AppleAppStoreIcon
            fill="var(--color-bg-secondary)"
            fill2="var(--color-bg-primary)"
            width={140}
          />
        </div>
        <div
          onClick={() => toggleDialog(playStoreUrl)}
          style={{ cursor: "pointer" }}
        >
          <AndroidAppStoreIcon
            fill="var(--color-bg-primary)"
            fill2="var(--color-bg-secondary)"
            width={158}
          />
        </div>
      </div>
      <span
        style={landingPageStyle}
        className="app-landing-coming-soon-primary-subtext"
      >
        Betas for iOS & Android, Now Live!
      </span>
    </div>
  );
};

export default AppStore;
