import React, { useState, useEffect } from "react";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import AppStore from "../../atoms/app-store/AppStore";
import "./Banner.scss";

const Banner = ({ text, text1, text2, text3, text4, logoPosition }) => {
  const words = [text, text1, text2, text3, text4].filter(
    (word) => word !== ""
  );

  const [dimensions, setDimensions] = useState({ width: 140, height: 80 });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const matchMedia = window.matchMedia("(max-width: 1080px)").matches;
      setIsMobile(matchMedia);
      setDimensions(
        matchMedia ? { width: 80, height: 50 } : { width: 140, height: 80 }
      );
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderLine = (start, end) => {
    let content = [];
    for (let i = start; i <= end; i++) {
      if (i === logoPosition && !isMobile) {
        content.push(
          <div key="logo" className="app-landing-page-logo-container">
            <WaivlengthLogo fill="var(--color-bg-secondary)" {...dimensions} />
          </div>
        );
      }
      content.push(
        <div key={i} className="app-landing-page-text-container">
          <span className="app-landing-hero-primary-text">{words[i]}</span>
        </div>
      );
    }
    return content;
  };

  const renderMobileLines = () => {
    if (words.length === 4) {
      return words.map((word, index) => (
        <div key={index} className="banner-hero-text-line">
          <div className="app-landing-page-text-container">
            <span className="app-landing-hero-primary-text">{word}</span>
          </div>{" "}
          {index === 2 && (
            <div className="app-landing-page-logo-container">
              <WaivlengthLogo
                fill="var(--color-bg-secondary)"
                {...dimensions}
              />
            </div>
          )}
        </div>
      ));
    } else if (words.length === 5) {
      return (
        <>
          {words.slice(0, 2).map((word, i) => (
            <div key={`line1-${i}`} className="banner-hero-text-line">
              <div className="app-landing-page-text-container">
                <span className="app-landing-hero-primary-text">{word}</span>
              </div>
            </div>
          ))}
          <div className="banner-hero-text-line">
            {words.slice(2, 4).map((word, i) => (
              <div
                key={`line3-${i}`}
                className="app-landing-page-text-container"
              >
                <span className="app-landing-hero-primary-text">{word}</span>
              </div>
            ))}
          </div>
          <div className="banner-hero-text-line">
            <div className="app-landing-page-text-container">
              <span className="app-landing-hero-primary-text">{words[4]}</span>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="banner-hero-section">
      {isMobile ? (
        renderMobileLines()
      ) : (
        <>
          <div className="banner-hero-text-line">
            {renderLine(0, words.length > 3 ? 1 : words.length - 1)}
          </div>
          <div className="banner-hero-text-line">
            {renderLine(
              words.length > 3 ? 2 : words.length - 1,
              words.length - 1
            )}
          </div>
        </>
      )}
      {words.includes("Waivlength") && (
        <div className="app-store-banner">
          <AppStore />
        </div>
      )}
    </div>
  );
};

export default Banner;
