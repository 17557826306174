import React, { useState, useEffect } from "react";
import "./FeaturedSpaces.scss";
import image1 from "../../assets/image/NearLogo.png";
import image2 from "../../assets/image/AzukiLogo.png";
import image3 from "../../assets/image/MAYCLogo.png";
import image4 from "../../assets/image/WaivlengthLogo.png";
import image5 from "../../assets/image/ApeCoinLogo.png";
import image6 from "../../assets/image/UniswapLogo.png";
import image7 from "../../assets/image/ManaLogo.png";
import FeaturedSpaceItem from "../../atoms/featured-space-item/FeaturedSpaceItem";
import BackgroundHeaderGradient from "../../assets/svg/background-header-gradient";

const featuredItems = [
  {
    id: 1,
    imageSrc: image1,
    transformX: -400,
    transformY: -100,
    width: 160,
    height: 160,
    zIndex: 0,
  },
  {
    id: 2,
    imageSrc: image2,
    transformX: -160,
    transformY: -180,
    width: 120,
    height: 120,
    zIndex: 2,
  },
  {
    id: 3,
    imageSrc: image3,
    transformX: 160,
    transformY: -100,
    width: 200,
    height: 200,
    zIndex: 3,
  },
  {
    id: 4,
    imageSrc: image4,
    transformX: 420,
    transformY: -100,
    width: 140,
    height: 140,
    zIndex: 1,
  },
  {
    id: 5,
    imageSrc: image5,
    transformX: -106,
    transformY: 170,
    width: 160,
    height: 160,
    zIndex: 3,
  },
  {
    id: 6,
    imageSrc: image6,
    transformX: -320,
    transformY: 100,
    width: 120,
    height: 120,
    zIndex: 1,
  },
  {
    id: 7,
    imageSrc: image7,
    transformX: 308,
    transformY: 130,
    width: 120,
    height: 120,
    zIndex: 3,
  },
];

const mobileFeaturedItems = [
  {
    id: 1,
    imageSrc: image1,
    transformX: -260,
    transformY: -220,
    width: 100,
    height: 100,
    zIndex: 0,
  },
  {
    id: 2,
    imageSrc: image2,
    transformX: -160,
    transformY: -80,
    width: 100,
    height: 100,
    zIndex: 3,
  },
  {
    id: 3,
    imageSrc: image3,
    transformX: 140,
    transformY: -160,
    width: 160,
    height: 160,
    zIndex: 3,
  },
  {
    id: 4,
    imageSrc: image4,
    transformX: 420,
    transformY: -100,
    width: 140,
    height: 140,
    zIndex: 1,
  },
  {
    id: 5,
    imageSrc: image5,
    transformX: -96,
    transformY: 120,
    width: 140,
    height: 140,
    zIndex: 3,
  },
  {
    id: 6,
    imageSrc: image6,
    transformX: 110,
    transformY: 224,
    width: 110,
    height: 110,
    zIndex: 1,
  },
  {
    id: 7,
    imageSrc: image7,
    transformX: 308,
    transformY: 130,
    width: 120,
    height: 120,
    zIndex: 3,
  },
];

const FeaturedSpaces = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="featured-spaces-container">
      <div className="featured-spaces-background-gradient">
        <BackgroundHeaderGradient
          height={1400}
          width={1400}
          fillOpacity={"0.6"}
          fillOpacityWhite={"0.4"}
          fill="var(--color-accent-one)"
        />
      </div>
      {(isMobile ? mobileFeaturedItems : featuredItems).map((item) => (
        <FeaturedSpaceItem key={item.id} {...item} />
      ))}
      <div className="featured-spaces-text-container">
        <span className="featured-spaces-primary-text">Featured</span>
      </div>
      <div className="featured-spaces-text-overlay-container">
        <span className="featured-spaces-primary-text-overlay">Featured</span>
      </div>
      <div className="featured-spaces-text1-container">
        <span className="featured-spaces-primary-text">Spaces</span>
      </div>
      <div className="featured-spaces-text1-overlay-container">
        <span className="featured-spaces-primary-text-overlay">Spaces</span>
      </div>
    </div>
  );
};

export default FeaturedSpaces;
