import React, { useState, useEffect } from "react";
import useScrollTo from "../../hooks/useScrollTo";
import "./NavigationButton.scss";

const NavigationButton = ({
  buttonText,
  IconComponent,
  scrollPosition,
  onClick,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollTo = useScrollTo(scrollPosition);

  const handleClick = () => {
    scrollTo();
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="navigation-button-wrapper" onClick={handleClick}>
      <div className="icon-component">
        <IconComponent
          stroke={
            windowWidth <= 768
              ? "var(--color-bg-primary)"
              : "var(--color-bg-secondary)"
          }
          strokeWidth={2}
          height={16}
          width={16}
        />
      </div>
      <span className="navigation-button-text">{buttonText}</span>
    </div>
  );
};

export default NavigationButton;
