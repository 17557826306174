import { useCallback } from "react";

const useScrollTo = (position) => {
  const scrollTo = useCallback(() => {
    window.scrollTo({
      top: position,
      left: 0,
      behavior: "smooth",
    });
  }, [position]);

  return scrollTo;
};

export default useScrollTo;
