import React from "react";
import "./CarouselItem.scss";

function updateProgressBar(
  progressBarElement,
  totalFundraised,
  totalFundraiseGoal
) {
  const progressPercentage = (totalFundraised / totalFundraiseGoal) * 100;
  progressBarElement.style.width = `${progressPercentage}%`;
}

const CarouselItem = ({
  id,
  imageSrc,
  bannerSrc,
  primaryText,
  secondaryText,
  tertiaryText,
  minContribution,
  maxContribution,
  totalFundraiseGoal,
  totalFundraised,
}) => {
  const progressBarRef = React.useRef(null);

  React.useEffect(() => {
    if (
      progressBarRef.current &&
      totalFundraised != null &&
      totalFundraiseGoal != null
    ) {
      updateProgressBar(
        progressBarRef.current,
        totalFundraised,
        totalFundraiseGoal
      );
    }
  }, [totalFundraised, totalFundraiseGoal]);

  return (
    <div className="featured-carousel-item" key={id}>
      <div className="carousel-item-header-container">
        <div className="carousel-item-banner">
          {bannerSrc && <img src={bannerSrc} alt="Banner" />}
        </div>
        <div className="carousel-item-image">
          {imageSrc && <img src={imageSrc} alt="Carousel item" />}
        </div>
      </div>
      <div className="carousel-text-content">
        <div className="carousel-text-content-header">
          <span className="carousel-text-header-primary-text">
            {primaryText}
          </span>
          <button className="carousel-text-header-ticker">
            <span className="carousel-text-header-tertiary-text">
              ${tertiaryText}
            </span>
          </button>
        </div>
        <span className="carousel-text-header-secondary-text">
          {secondaryText}
        </span>
        <div className="carousel-text-content-footer">
          <div className="carousel-text-footer-container">
            <span className="carousel-text-footer-title-text">
              Fundraising Goal
            </span>
            <span className="carousel-text-footer-body-text">
              {totalFundraiseGoal} ETH
            </span>
          </div>
          <div className="carousel-text-footer-contributions">
            <div className="carousel-text-footer-container">
              <span className="carousel-text-footer-title-text">
                Minimum Contribution
              </span>
              <span className="carousel-text-footer-body-text">
                {minContribution} ETH
              </span>
            </div>
            <div className="carousel-text-footer-container">
              <span className="carousel-text-footer-title-text">
                Maximum Contribution
              </span>
              <span className="carousel-text-footer-body-text">
                {maxContribution} ETH
              </span>
            </div>
          </div>
          <div className="carousel-text-footer-container">
            <span className="carousel-text-footer-title-text">
              Total Raised
            </span>
            <span className="carousel-text-footer-body-text">
              {totalFundraised} ETH
            </span>
          </div>
          <div className="progress-container">
            <div className="progress-bar" ref={progressBarRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
