import React, { useEffect, useState } from "react";
import "./Landing.scss";
import Navigation from "../../components/navigation/Navigation";
import Hero from "../../components/hero/Hero";
import AppDisplay from "../../components/app-display/AppDisplay";
import { BannerSection } from "../../components/banner-section/BannerSection";
import FeaturesSection from "../../components/features-section/FeaturesSection";
import CommunitiesSection from "../../components/communities-section/CommunitiesSection";
import FutureFeaturesSection from "../../components/future-features-section/FutureFeaturesSection";
import Footer from "../../components/footer/Footer";
import FeaturedSalesSection from "../../components/featured-sales-section/FeaturedSalesSection";
import BackgroundHeaderGradient from "../../assets/svg/background-header-gradient";
import useGradientScrollOpacity from "../../hooks/useGradientScrollOpacity";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

function Landing() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getStartPosition = () => {
    if (windowWidth >= 1024) {
      return 8250;
    } else if (windowWidth < 1024) {
      return 8550;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLockBodyScroll(true);

  const { headerStyle: landingPageHeaderStyle } = useGradientScrollOpacity();

  return (
    <div className="app-wrapper">
      <div
        style={landingPageHeaderStyle}
        className="app-background-header-gradient"
      >
        <BackgroundHeaderGradient
          height={1900}
          width={1900}
          fillOpacity={"0.6"}
          fillOpacityWhite={"0.4"}
          fill="var(--color-accent-one)"
        />
      </div>
      <Navigation />
      <Hero />
      <AppDisplay />
      <BannerSection
        height={1300}
        startPosition={1000}
        range={600}
        stay={400}
        text="Do"
        text1="things"
        text2="the"
        text3="way"
        text4=""
        logoPosition={3}
      />
      <FeaturesSection />
      <BannerSection
        height={1300}
        startPosition={3150}
        range={600}
        stay={400}
        text="Users,"
        text1="discover"
        text2="your"
        text3="community"
        text4=""
        logoPosition={3}
      />
      <CommunitiesSection />
      <FeaturedSalesSection />
      <BannerSection
        height={1300}
        startPosition={6150}
        range={600}
        stay={400}
        text="Future"
        text1="features"
        text2="if"
        text3="WAGMI"
        text4=""
        logoPosition={3}
      />
      <FutureFeaturesSection />
      <BannerSection
        height={1300}
        startPosition={getStartPosition()}
        range={600}
        stay={400}
        text="Download"
        text1="Waivlength"
        text2="and"
        text3="get"
        text4="started"
        logoPosition={1}
      />
      <Footer />
    </div>
  );
}

export default Landing;
