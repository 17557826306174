import React, { useState, useEffect } from "react";
import { useDialog } from "../../contexts/DialogContext";
import "./SignUpDialog.scss";
import { addEmailAndAddress } from "./Firebase";
import { validateAndExecute } from "../../utils/validateAndExecute";
import XLogo from "../../assets/svg/X-logo";
import CloseIcon from "../../assets/svg/close-icon";
import image1 from "../../assets/image/Signup1.png";
import image2 from "../../assets/image/Signup2.png";
import { Link } from "react-router-dom";

const SignUpDialog = () => {
  const { isVisible, toggleDialog } = useDialog();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isVisible]);

  const openTwitter = () =>
    window.open("https://twitter.com/waivlength_io", "_blank");

  const handleAddEmail = (acceptTermsAndPrivacy) => {
    validateAndExecute({
      input: email,
      acceptTermsAndPrivacy: acceptTermsAndPrivacy,
      validateInput: {
        isValid: (email) => {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailPattern.test(email);
        },
        failureMessage: "Email is invalid",
        logMessage: "Email check failed: Email is invalid",
      },
      action: addEmailAndAddress,
      onSuccess: (msg) => {
        setMessage(msg);
      },
      onError: (msg) => {
        setMessage(msg);
      },
      onValidationFailure: setValidationMessage,
      resetInput: () => setEmail(""),
    });
  };

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      toggleDialog();
    }
  };

  return (
    <div
      className={`dialog-background ${isVisible ? "visible" : ""}`}
      onClick={handleBackgroundClick}
    >
      <div className="dialog-content" onClick={(e) => e.stopPropagation()}>
        <div className="dialog-content-container">
          <div className="dialog-sign-up-top">
            <div className="dialog-sign-up-tag">
              <span className="dialog-sign-up-button-primary-text">
                Sign up for early access
              </span>
            </div>
            <div onClick={toggleDialog} className="dialog-sign-up-close">
              <CloseIcon
                fill="var(--color-bg-primary)"
                height={16}
                width={16}
              />
            </div>
          </div>
          <span className="dialog-sign-up-header-text">
            Be first to try our beta Mobile app!
          </span>
          <span className="dialog-sign-up-body-text">
            When our mobile app launches, early access tokens will let you
            access it before anyone else.
          </span>
          <div className="dialog-sign-up-input-container">
            <span className="dialog-sign-up-footer-text">
              Enter email for early access
            </span>
            <input
              className="dialog-sign-up-input"
              placeholder={message || "Add Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="dialog-sign-up-button"
              onClick={() => handleAddEmail(true)}
            >
              <span className="dialog-sign-up-button-text">
                {validationMessage || "Sign up"}
              </span>
            </button>
            <span className="dialog-sign-up-terms-text">
              By signing up, you agree to the{" "}
              <Link
                to="/legal/terms-of-use"
                className="terms-link"
                onClick={toggleDialog}
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                to="/legal/privacy-policy"
                className="terms-link"
                onClick={toggleDialog}
              >
                Privacy Policy
              </Link>
              .
            </span>
          </div>
          <div className="dialog-sign-up-socials-container">
            <span className="dialog-sign-up-header-socials-header-text">
              Know when Desktop beta is released!
            </span>
            <span className="dialog-sign-up-header-socials-text">
              @waivlength_io will be posting updates
            </span>
            <div onClick={openTwitter} className="dialog-sign-up-social">
              <span className="dialog-sign-up-button-primary-text">
                Follow us on
              </span>
              <XLogo fill="var(--color-bg-primary)" height={16} width={16} />
            </div>
          </div>
        </div>
        <div className="dialog-mobile-preview-container">
          <div onClick={toggleDialog} className="dialog-sign-up-close-right">
            <CloseIcon fill="var(--color-bg-primary)" height={16} width={16} />
          </div>
          <div className="dialog-sign-up-app-container">
            <img
              src={image1}
              alt="app-display"
              className="dialog-sign-up-app"
            />
            <img
              src={image2}
              alt="app-display"
              className="dialog-sign-up-app"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpDialog;
