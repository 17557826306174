import { useState, useEffect } from "react";

const INITIAL_FADE_IN_DELAY = 2000;
const INITIAL_FADE_IN_DURATION = 600;
const SCROLL_FADE_IN_DURATION = 600;
const HEADER_MAX_SCROLL = 400;
const FOOTER_START_SCROLL = 7600;
const FOOTER_FADE_RANGE = 600;

function useGradientScrollOpacity() {
  const [hasInitialHeaderAnimated, setHasInitialHeaderAnimated] = useState(
    () => sessionStorage.getItem("hasInitialHeaderAnimated") === "true"
  );
  const hasInitialFooterAnimated = useState(
    () => sessionStorage.getItem("hasInitialFooterAnimated") === "true"
  )[0]; // Only retrieving the state, not the setter

  const [headerStyle, setHeaderStyle] = useState({
    opacity: hasInitialHeaderAnimated ? 1 : 0,
    transition: `opacity ${SCROLL_FADE_IN_DURATION}ms ease-in-out`,
  });

  const [footerStyle, setFooterStyle] = useState({
    opacity: hasInitialFooterAnimated ? 1 : 0,
    transition: `opacity ${SCROLL_FADE_IN_DURATION}ms ease-in-out`,
  });

  useEffect(() => {
    let headerTimer; // Define headerTimer within the useEffect
    if (!hasInitialHeaderAnimated) {
      headerTimer = setTimeout(() => {
        setHeaderStyle({
          opacity: 1,
          transition: `opacity ${INITIAL_FADE_IN_DURATION}ms ease-in`,
        });
        sessionStorage.setItem("hasInitialHeaderAnimated", "true");
        setHasInitialHeaderAnimated(true);
        setTimeout(() => {
          setHeaderStyle((prevStyle) => ({
            ...prevStyle,
            transition: `opacity ${SCROLL_FADE_IN_DURATION}ms linear`,
          }));
        }, INITIAL_FADE_IN_DURATION);
      }, INITIAL_FADE_IN_DELAY);
    }

    const handleScroll = () => {
      const scrollY = window.scrollY;
      let headerOpacity = 1 - scrollY / HEADER_MAX_SCROLL;
      headerOpacity = Math.max(0, Math.min(1, headerOpacity));
      setHeaderStyle((prevStyle) => ({
        ...prevStyle,
        opacity: headerOpacity,
        transition: `opacity ${SCROLL_FADE_IN_DURATION}ms linear`,
      }));

      if (scrollY <= FOOTER_START_SCROLL && !hasInitialFooterAnimated) {
        setFooterStyle({
          opacity: 0,
          transition: `opacity ${SCROLL_FADE_IN_DURATION}ms ease-in-out`,
        });
      } else {
        const footerOpacity =
          (scrollY - FOOTER_START_SCROLL) / FOOTER_FADE_RANGE;
        setFooterStyle({
          opacity: Math.min(1, footerOpacity),
          transition: `opacity ${SCROLL_FADE_IN_DURATION}ms ease-in-out`,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      if (headerTimer) {
        clearTimeout(headerTimer);
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasInitialHeaderAnimated, hasInitialFooterAnimated]);

  return { headerStyle, footerStyle };
}

export default useGradientScrollOpacity;
